// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrow-big {
  display: inline-block;
  opacity: 0;
  transition: transform 1.1s cubic-bezier(var(--cubic-bezier)), opacity 1.1s cubic-bezier(var(--cubic-bezier)), line-height 1.1s cubic-bezier(var(--cubic-bezier));
  transform: translateY(-20px) scaleY(0);
  transform-origin: top;
}
.arrow-big-img {
  width: 130px;
  height: 100px;
}

.arrow-big.visible {
  transform: translateY(0) scaleY(1);
  opacity: 1;
}

@media all and (min-width: 375px) and (max-width: 1000px) {
  .arrow-big-img {
    width: 100px;
    height: 70px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/arrow-big/style.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,UAAU;EACV,gKAAgK;EAChK,sCAAsC;EACtC,qBAAqB;AACvB;AACA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,kCAAkC;EAClC,UAAU;AACZ;;AAEA;EACE;IACE,YAAY;IACZ,YAAY;EACd;AACF","sourcesContent":[".arrow-big {\n  display: inline-block;\n  opacity: 0;\n  transition: transform 1.1s cubic-bezier(var(--cubic-bezier)), opacity 1.1s cubic-bezier(var(--cubic-bezier)), line-height 1.1s cubic-bezier(var(--cubic-bezier));\n  transform: translateY(-20px) scaleY(0);\n  transform-origin: top;\n}\n.arrow-big-img {\n  width: 130px;\n  height: 100px;\n}\n\n.arrow-big.visible {\n  transform: translateY(0) scaleY(1);\n  opacity: 1;\n}\n\n@media all and (min-width: 375px) and (max-width: 1000px) {\n  .arrow-big-img {\n    width: 100px;\n    height: 70px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
