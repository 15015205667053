// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-bar-wrapper {
  display: flex;
  align-items: center;
  padding: 40px 0px;
  margin: 0 2.5em 0 3.5em;
  justify-content: space-between;
  transform: translateY(-100%);
  transition: transform 0.5s ease-in-out 0.8s;
  background: var(--primary-color);
  overflow: hidden;
  opacity: 0;
}


.nav-bar-wrapper.visible {
  transform: translateY(0);
  opacity:1
}

.nav-bar-wrapper.visible.nav-bar-wrapper---sticky {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  color: black;
  mix-blend-mode: difference;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-tap-highlight-color: transparent;
 
}
@media all and (min-width: 375px) and (max-width: 450px) {
  .nav-bar-wrapper {
    margin: 0 0.5em 0 1.5em;
    padding-bottom: 0;
  }
}
@media all and (min-width: 451px) and (max-width: 900px) {
  .nav-bar-wrapper {
    margin: 0 0.5em 0 1.5em;
    
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/nav-bar/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,uBAAuB;EACvB,8BAA8B;EAC9B,4BAA4B;EAC5B,2CAA2C;EAC3C,gCAAgC;EAChC,gBAAgB;EAChB,UAAU;AACZ;;;AAGA;EACE,wBAAwB;EACxB;AACF;;AAEA;EACE,gBAAgB;EAChB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,aAAa;EACb,YAAY;EACZ,0BAA0B;EAC1B,qBAAqB;EACrB,oCAAoC;EACpC,6BAA6B;EAC7B,wCAAwC;;AAE1C;AACA;EACE;IACE,uBAAuB;IACvB,iBAAiB;EACnB;AACF;AACA;EACE;IACE,uBAAuB;;EAEzB;AACF","sourcesContent":[".nav-bar-wrapper {\n  display: flex;\n  align-items: center;\n  padding: 40px 0px;\n  margin: 0 2.5em 0 3.5em;\n  justify-content: space-between;\n  transform: translateY(-100%);\n  transition: transform 0.5s ease-in-out 0.8s;\n  background: var(--primary-color);\n  overflow: hidden;\n  opacity: 0;\n}\n\n\n.nav-bar-wrapper.visible {\n  transform: translateY(0);\n  opacity:1\n}\n\n.nav-bar-wrapper.visible.nav-bar-wrapper---sticky {\n  position: sticky;\n  top: 0;\n  left: 0;\n  right: 0;\n  z-index: 1000;\n  color: black;\n  mix-blend-mode: difference;\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n  -webkit-background-clip: text;\n  -webkit-tap-highlight-color: transparent;\n \n}\n@media all and (min-width: 375px) and (max-width: 450px) {\n  .nav-bar-wrapper {\n    margin: 0 0.5em 0 1.5em;\n    padding-bottom: 0;\n  }\n}\n@media all and (min-width: 451px) and (max-width: 900px) {\n  .nav-bar-wrapper {\n    margin: 0 0.5em 0 1.5em;\n    \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
