// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  margin: 0;
  padding: 0;
  background-color: black;
}

.container {
  width: 100%;
  transform: translateY(100vh);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  background-color: var(--primary-color);
  opacity: 0;
}

.container.slide-in {
  transform: translateY(0);
  opacity: 1;
}
.page {
  background-color: var(--primary-color);
  position: relative;
  padding: 0 7.5em;
  display: flex;
  flex-direction: column;
}

.show-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: bottom;
}
.show-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  transform-origin: top;
}

.page-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media all and (min-width: 375px) and (max-width: 900px) {
  .page {
    padding: 6em 1.5em 1em 1.5em;
  }
}

@media all and (min-height: 900px) {
  .page {
    padding-top: 7.5em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/home-page/style.css"],"names":[],"mappings":"AAAA;;EAEE,SAAS;EACT,UAAU;EACV,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,4BAA4B;EAC5B,gEAAgE;EAChE,sCAAsC;EACtC,UAAU;AACZ;;AAEA;EACE,wBAAwB;EACxB,UAAU;AACZ;AACA;EACE,sCAAsC;EACtC,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,wBAAwB;AAC1B;AACA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;AACA;EACE;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":["html,\nbody {\n  margin: 0;\n  padding: 0;\n  background-color: black;\n}\n\n.container {\n  width: 100%;\n  transform: translateY(100vh);\n  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;\n  background-color: var(--primary-color);\n  opacity: 0;\n}\n\n.container.slide-in {\n  transform: translateY(0);\n  opacity: 1;\n}\n.page {\n  background-color: var(--primary-color);\n  position: relative;\n  padding: 0 7.5em;\n  display: flex;\n  flex-direction: column;\n}\n\n.show-in {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  transform-origin: bottom;\n}\n.show-out {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: black;\n  transform-origin: top;\n}\n\n.page-bottom {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n@media all and (min-width: 375px) and (max-width: 900px) {\n  .page {\n    padding: 6em 1.5em 1em 1.5em;\n  }\n}\n\n@media all and (min-height: 900px) {\n  .page {\n    padding-top: 7.5em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
