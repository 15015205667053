// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-wrapper {
  margin: 0;
  display: flex;
  list-style: none;
  font-family: var(--font-family-titles);
  justify-content: space-between;
  width: 100%;
 
}

.nav-wrapper a {
  padding-right: 0.9rem;
  margin: 10px 0;
  height: 1.5rem;
  letter-spacing: -0.03rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.15rem;
  text-decoration: none;
  color: black;
}


@media all and (min-width: 375px) and (max-width: 560px) {
  .nav-links {
    display: flex;
    flex-direction: column;
  }
  .nav-links a {
    margin: 0;
  }
}
@media all and (min-width: 1900px) {
  .nav-wrapper a {
    font-size: 1.5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/nav/style.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,aAAa;EACb,gBAAgB;EAChB,sCAAsC;EACtC,8BAA8B;EAC9B,WAAW;;AAEb;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,cAAc;EACd,wBAAwB;EACxB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;AACd;;;AAGA;EACE;IACE,aAAa;IACb,sBAAsB;EACxB;EACA;IACE,SAAS;EACX;AACF;AACA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".nav-wrapper {\n  margin: 0;\n  display: flex;\n  list-style: none;\n  font-family: var(--font-family-titles);\n  justify-content: space-between;\n  width: 100%;\n \n}\n\n.nav-wrapper a {\n  padding-right: 0.9rem;\n  margin: 10px 0;\n  height: 1.5rem;\n  letter-spacing: -0.03rem;\n  cursor: pointer;\n  font-weight: bold;\n  font-size: 1.15rem;\n  text-decoration: none;\n  color: black;\n}\n\n\n@media all and (min-width: 375px) and (max-width: 560px) {\n  .nav-links {\n    display: flex;\n    flex-direction: column;\n  }\n  .nav-links a {\n    margin: 0;\n  }\n}\n@media all and (min-width: 1900px) {\n  .nav-wrapper a {\n    font-size: 1.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
