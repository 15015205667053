// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-wrapper {
  display: flex;
  background-color: black;
  width: 100vw;
  bottom: 0;
  left: 0;
  transition: height 0.3s ease;
  overflow: hidden;
  position: absolute;
  opacity: 0;
  transition: opacity 1.1s cubic-bezier(var(--cubic-bezier));
}

.footer p {
  font-weight: bold;
  color: #02181f;
  opacity: 0.7;
  width: 20vw;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  align-items: center;
  color: white;
  font-family: var(--font-family);
}

.footer {
  display: flex;
  white-space: nowrap;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ticker;
  animation-duration: 30s;
}

@keyframes ticker {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100vw);
  }
}

@media all and (min-width: 375px) and (max-width: 450px) {
  .footer p {
    width: 50vw;
  }
}

@media all and (min-width: 375px) and (max-width: 900px) {
  .footer p {
    width: 50vw;
  }

  .footer {
    height: 40px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,SAAS;EACT,OAAO;EACP,4BAA4B;EAC5B,gBAAgB;EAChB,kBAAkB;EAClB,UAAU;EACV,0DAA0D;AAC5D;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,YAAY;EACZ,WAAW;EACX,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;EACZ,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,mBAAmB;EAEnB,mCAAmC;EAEnC,iCAAiC;EAEjC,sBAAsB;EAEtB,uBAAuB;AACzB;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,6BAA6B;EAC/B;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;EACb;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".footer-wrapper {\n  display: flex;\n  background-color: black;\n  width: 100vw;\n  bottom: 0;\n  left: 0;\n  transition: height 0.3s ease;\n  overflow: hidden;\n  position: absolute;\n  opacity: 0;\n  transition: opacity 1.1s cubic-bezier(var(--cubic-bezier));\n}\n\n.footer p {\n  font-weight: bold;\n  color: #02181f;\n  opacity: 0.7;\n  width: 20vw;\n  flex-shrink: 0;\n  display: flex;\n  justify-content: center;\n  white-space: nowrap;\n  align-items: center;\n  color: white;\n  font-family: var(--font-family);\n}\n\n.footer {\n  display: flex;\n  white-space: nowrap;\n  -webkit-animation-iteration-count: infinite;\n  animation-iteration-count: infinite;\n  -webkit-animation-timing-function: linear;\n  animation-timing-function: linear;\n  -webkit-animation-name: ticker;\n  animation-name: ticker;\n  -webkit-animation-duration: 8s;\n  animation-duration: 30s;\n}\n\n@keyframes ticker {\n  from {\n    transform: translateX(0);\n  }\n  to {\n    transform: translateX(-100vw);\n  }\n}\n\n@media all and (min-width: 375px) and (max-width: 450px) {\n  .footer p {\n    width: 50vw;\n  }\n}\n\n@media all and (min-width: 375px) and (max-width: 900px) {\n  .footer p {\n    width: 50vw;\n  }\n\n  .footer {\n    height: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
